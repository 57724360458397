import React from "react";
const CanalMyFits = (props) => {
    return (
        <div>
            <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Você ainda não não possui nenhum FIT.
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Um FIT é quando um Fornecedor COMBINA com você.</p>
        </div>
    )
}

export default CanalMyFits;